import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'reactstrap';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import type { IBlotterErrorType } from 'state/blotter/blotterModel';
import { RefToggle } from 'components/share/RefToggle';
import { connectBlotterErrors } from './connect';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { isEmpty } from '@sgme/fp';
const messagesMap = { en, fr };

export interface BlotterErrorProps {
  errors: readonly IBlotterErrorType[];
  errorBlotterType: string | null;
}

export function BlotterErrorIndicatorRaw({ errors, errorBlotterType }: BlotterErrorProps) {
  if (isEmpty(errors)) {
    return null;
  }

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <RefToggle<HTMLElement>>
        {({ ref, isOpen, target, toggle }) => (
          <span ref={ref} className="fs-12 text-danger cursor-default">
            <i className="icon icon-sm line-height-1 btn-group">error_outline</i>{' '}
            <FormattedMessage id="blotterError.label" />
            {target !== null && (
              <Tooltip
                placement="top"
                delay={0}
                fade={false}
                innerClassName="mw-100"
                isOpen={isOpen}
                target={target}
                toggle={toggle}
              >
                {errors.map((error, index) => (
                  <div key={index} className="mt-3 text-start">
                    <FormattedMessage id={`blotterError.${error}.${errorBlotterType || 'default'}`} />
                  </div>
                ))}
                <div key="contactSupport" className="my-3 text-start">
                  <FormattedMessage id="blotterError.contactSupport" />
                </div>
              </Tooltip>
            )}
          </span>
        )}
      </RefToggle>
    </IntlComponentBoundary>
  );
}

export const BlotterErrorIndicator = connectBlotterErrors(BlotterErrorIndicatorRaw);
