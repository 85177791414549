import { isNotDefined } from "@sgme/fp";
import { SgwtHelpCenter, useSgwtWidgets } from "@sgwt/sgwt-widgets-react";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { getEnvironment } from "../../../configuration";

const HelpCenterWebAnalyticsCategory = "Help center";

// Source = https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/sgwt-help-center.html
type HelpCenterNextEvent = CustomEvent<{
  action: string;
  categoryId: string;
  topicId: string;
  title: string;
}>;
type HelpCenterPreviousEvent = CustomEvent<{
  nb: number;
}>;
type HelpCenterTopicEvent = CustomEvent<{ topicId: string }>;
type HelpCenterCategoryEvent = CustomEvent<{ categoryId: string }>;

export function HelpCenter(): JSX.Element {
  const { locale } = useIntl();

  const { sgwtHelpCenter, sgwtWebAnalytics } = useSgwtWidgets();

  useEffect(() => {
    if (isNotDefined(sgwtHelpCenter) || isNotDefined(sgwtWebAnalytics)) return;

    //
    // Help center listeners
    //

    function handleOpen() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, "Open");
    }

    function handleClose() {
      sgwtWebAnalytics?.trackEvent(HelpCenterWebAnalyticsCategory, "Close");
    }

    function handleNext(event: Event) {
      const detail = (event as HelpCenterNextEvent).detail;
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        `Click on "${detail.categoryId ?? detail.topicId}"`,
        detail.title
      );
    }

    function handlePrevious(event: Event) {
      const detail = (event as HelpCenterPreviousEvent).detail;
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        `Click on previous (${detail.nb})"`
      );
    }

    function handleTopic(event: Event) {
      const detail = (event as HelpCenterTopicEvent).detail;
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        `Click on "${detail.topicId}"`
      );
    }

    function handleCategory(event: Event) {
      const detail = (event as HelpCenterCategoryEvent).detail;
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        `Click on "${detail.categoryId}"`
      );
    }

    function handleOpenForm() {
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        "Open Message Form"
      );
    }

    function handleFormSend() {
      sgwtWebAnalytics?.trackEvent(
        HelpCenterWebAnalyticsCategory,
        "Send Message"
      );
    }

    //
    // register listeners
    //
    sgwtHelpCenter.addEventListener("sgwt-help-center--open", handleOpen);
    sgwtHelpCenter.addEventListener("sgwt-help-center--close", handleClose);
    sgwtHelpCenter.addEventListener("sgwt-help-center--next", handleNext);
    sgwtHelpCenter.addEventListener(
      "sgwt-help-center--previous",
      handlePrevious
    );
    sgwtHelpCenter.addEventListener("sgwt-help-center--send", handleFormSend);
    sgwtHelpCenter.addEventListener(
      "sgwt-help-center--category",
      handleCategory
    );
    sgwtHelpCenter.addEventListener("sgwt-help-center--topic", handleTopic);
    sgwtHelpCenter.addEventListener("sgwt-help-center--form", handleOpenForm);

    return () => {
      //
      // unregister lineners
      //
      sgwtHelpCenter?.removeEventListener("sgwt-help-center--open", handleOpen);
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--close",
        handleClose
      );
      sgwtHelpCenter.removeEventListener("sgwt-help-center--next", handleNext);
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--previous",
        handlePrevious
      );
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--send",
        handleFormSend
      );
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--category",
        handleCategory
      );
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--topic",
        handleTopic
      );
      sgwtHelpCenter.removeEventListener(
        "sgwt-help-center--form",
        handleOpenForm
      );
    };
  }, [sgwtHelpCenter, sgwtWebAnalytics]);

  return (
    <aside>
      <SgwtHelpCenter
        language={locale}
        mailSubject={`[SGME-FX:${
          getEnvironment()?.toUpperCase() ?? "PROD"
        }] Help center message`}
        applicationId="sgme-fx"
        allowScreenshot={true}
      />
    </aside>
  );
}
