import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import {
  blotterDetailsPanelHidden,
  type BlotterMetadata,
  defaultBlotterMetadata,
  type IBlotterErrorType,
} from '../blotterModel';
import { filterArray, insertInArray } from 'utils/stateArray';
import { composeLeftPatcher, patcherReducer, patchValueByProperty, updateValueByProperty } from 'utils/stateMap';

const removeError = (errorValue: IBlotterErrorType) =>
  composeLeftPatcher<BlotterMetadata>(
    updateValueByProperty('errors')(errors => filterArray(errors!, error => error !== errorValue)),
  );
const removeNetworkError = removeError('network');
const removeLiveError = removeError('live');
const removeHistoricalError = removeError('historical');
const removeHistoricalOrderError = removeError('historicalOrder');

const addError = (errorValue: IBlotterErrorType) =>
  composeLeftPatcher<BlotterMetadata>(updateValueByProperty('errors')(errors => insertInArray(errors!, errorValue)));
const addNetworkError = addError('network');
const addLiveError = addError('live');
const addHistoricalError = addError('historical');
const addHistoricalOrderError = addError('historicalOrder');

const updateBlotterEnableShowMyMainDeals = (isVisible: boolean) =>
  composeLeftPatcher<BlotterMetadata>(updateValueByProperty('enableShowMyMainDeals')(() => isVisible));

export const metadataReducer: Reducer<BlotterMetadata> = (
  state = defaultBlotterMetadata,
  action: Action,
): BlotterMetadata => {
  switch (action.type) {
    case 'STREAMING_CONNECTED':
    case 'STREAMING_RECONNECTED':
      return removeNetworkError(state);
    case 'STREAMING_DISCONNECTED':
      return addNetworkError(state);
    case 'BLOTTER_TOGGLE':
      return {
        ...state,
        isOpen: action.isOpen ?? !state.isOpen,
      };
    case 'BLOTTER_UPDATE_MODE_METADATA':
      return {
        ...state,
        ...action.modeMetadata,
        detailsPanel: blotterDetailsPanelHidden,
      };
    case 'BLOTTER_CANT_REOPEN_ENTRY':
      return {
        ...state,
        cantReopenReason: action.reason,
      };
    case 'USER_PREFERENCES_DATA_CHANGED':
      if (action.patch.showMyMainDeals === undefined) {
        return state;
      }
      return {
        ...state,
        fetching: state.mode,
        canRefetch: false,
      };
    case 'BLOTTER_HISTORICAL_TRADES_REQUEST_FAILED':
      return {
        ...state,
        ...{
          fetching: null,
          canRefetch: true,
          historical: state.historical
            ? {
              ...state.historical,
              error: action.error,
              errorDetails: action.errorDetails,
            }
            : undefined,
        },
      };
    case 'BLOTTER_LIVE_REGISTER_SUCCESS':
      return removeLiveError({
        ...state,
        errorBlotterType: null
      });
    case 'BLOTTER_LIVE_REGISTER_FAILURE':
      return updateBlotterEnableShowMyMainDeals(true)(addLiveError({
        ...state,
        errorBlotterType: action.errorBlotterType
      }));
    case 'BLOTTER_TABLE_CRASHED':
      return addError('tableCrash')(state);
    case 'BLOTTER_INTRADAY_TRADES_REQUEST_FAILED':
      return updateBlotterEnableShowMyMainDeals(true)(
        addHistoricalError({ ...state, fetching: null, canRefetch: true }),
      );
    case 'BLOTTER_ORDER_LIST_REQUEST_FAILED':
      return addHistoricalOrderError(state);
    case 'BLOTTER_INTRADAY_TRADES_RECEIVED':
      return updateBlotterEnableShowMyMainDeals(true)(
        removeHistoricalError({ ...state, fetching: null, canRefetch: true }),
      );
    case 'BLOTTER_RETRIEVE_HISTORICAL_TRADES':
      return { ...state, fetching: 'historical' };
    case 'BLOTTER_ALLOW_REFETCH_TRADES':
      return { ...state, canRefetch: true };
    case 'BLOTTER_HISTORICAL_TRADES_RECEIVED':
      return { ...state, fetching: null, canRefetch: true };
    case 'BLOTTER_ORDER_LIST_RECEIVED':
      return removeHistoricalOrderError(state);
    case 'BLOTTER_TAB_CHANGED':
      if (action.tab === state.activeTab) {
        return state;
      }
      return {
        ...removeError('tableCrash')(state),
        activeTab: action.tab,
        detailsPanel: blotterDetailsPanelHidden,
      };
    case 'BLOTTER_PANEL_HEIGHT_CHANGED':
      return patcherReducer<BlotterMetadata>(state, () => ({
        panelHeight: action.height,
      }));
    case 'BLOTTER_TAB_METADATA_CHANGED':
      const { tab, tabMetatada } = action;
      switch (tab) {
        case 'cash':
          return patchValueByProperty(state, 'cashTab', () => tabMetatada);
        case 'option':
          return patchValueByProperty(state, 'optionTab', () => tabMetatada);
        case 'order':
          return patchValueByProperty(state, 'orderTab', () => tabMetatada);
        case 'accumulator':
          return patchValueByProperty(state, 'accumulatorTab', () => tabMetatada);
        default:
          return state;
      }
    case 'BLOTTER_SHOW_DETAILS_PANEL':
      return patchValueByProperty(state, 'detailsPanel', () => ({
        selectedId: action.selectedId,
        mode: action.mode,
        childrenIds: action.childrenIds,
        hasUnexpectedError: false,
        validationResponse: null,
      }));
    case 'BLOTTER_HIDE_DETAILS_PANEL':
      return patchValueByProperty(state, 'detailsPanel', () => blotterDetailsPanelHidden);
    case 'BLOTTER_SET_DETAILS_PANEL_MODE':
      return patchValueByProperty(state, 'detailsPanel', () => ({
        mode: action.mode,
        saveError: action.saveError,
      }));
    case 'BLOTTER_CHANGE_DETAILS_PANEL_UNEXPECTED_ERROR':
      return patchValueByProperty(state, 'detailsPanel', () => ({
        hasUnexpectedError: action.hasError,
      }));
    case 'BLOTTER_FETCH_INTRADAY_TRADES_STARTED':
      return patchValueByProperty(state, 'detailsPanel', () => ({
        selectedId: null,
      }));
    case 'BLOTTER_LIVE_REGISTER_START':
      return {
        ...state,
        enableShowMyMainDeals: false,
        detailsPanel: blotterDetailsPanelHidden,
      };
    case 'BLOTTER_ORDER_REJECTED_SHOW_DIALOG':
      return {
        ...state,
        orderRejectedId: action.orderId,
      };
    case 'BLOTTER_ORDER_REJECTED_HIDE_DIALOG':
      return {
        ...state,
        orderRejectedId: null,
      };
    case 'BLOTTER_ORDER_SAVE_ERROR_SHOW_DIALOG':
      return {
        ...state,
        showSaveErrorDialogBox: true,
        displayedErrorId: action.quoteId,
      };
    case 'BLOTTER_ORDER_SAVE_ERROR_HIDE_DIALOG':
      return {
        ...state,
        showSaveErrorDialogBox: false,
        displayedErrorId: undefined,
      };

    case 'BLOTTER_EDITED_ORDER_VALIDATION_RECEIVED':
      return {
        ...state,
        detailsPanel: {
          ...state.detailsPanel,
          // todo-5010 encapsuler les details de la reponse dans une propriété spécifique
          validationResponse: action,
        },
      };
    default:
      return state;
  }
};
