import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { IBlotterErrorType } from 'state/blotter/blotterModel';
import { isDefined } from '@sgme/fp';

export type BlotterErrorsConnectOwnProps = {}

export interface BlotterErrorsConnectStateProps {
  errors: readonly IBlotterErrorType[];
  errorBlotterType?: string | null;
}

// you can whitelist selectors after implementation
export type BlotterErrorsConnectSelectors = Selectors;
/*
export type BlotterErrorsConnectSelectorsKeys = 'aSelector'; // example
export type BlotterErrorsConnectSelectors = Pick<Selectors, BlotterErrorsConnectSelectorsKeys>;
*/

export const mapStateToPropsBlotterErrors: MapStateToPropsHOF<
  BlotterErrorsConnectStateProps,
  BlotterErrorsConnectOwnProps,
  AppState,
  BlotterErrorsConnectSelectors
> = sl => (state, _ownProps) => {

  const blottersInError = sl.getBlotterErrorBlotterType(state);
  const hasOneBlotterInError = isDefined(blottersInError) && blottersInError.length === 1; // check if it's only trade blotter or order blotter which has issue

  return {
    errors: sl.getBlotterErrors(state),
    errorBlotterType: hasOneBlotterInError === true ? blottersInError[0] : null
  }
};
