import type {
  BlotterTab,
  BlotterTabMetadata,
  BlotterModeMetadata,
  BlotterHistoricalError,
  CantReopenReason,
} from '../blotterModel';
import type { BlotterEntry, OrderBlotterEntry, TradeBlotterEntry } from '../blotterEntryModel';
export type BlotterAction =
  | BlotterToggle
  | BlotterUpdateModeMetadata
  | BlotterCantReopenEntry
  | BlotterEntryReceived
  | BlotterTabChanged
  | BlotterTabMetadataChanged
  | BlotterPanelHeightChanged
  | BlotterLiveRegisterSuccess
  | BlotterLiveRegisterFailure
  | BlotterOrderListReceived
  | BlotterOrderListReceivedFailed
  | BlotterIntradayTradesReceived
  | BlotterIntradayTradesRequestFailed
  | BlotterLiveRegisterStart
  | BlotterTableCrashed
  | BlotterRetrieveHistoricalTrades
  | BlotterAllowRefetchTrades
  | BlotterHistoricalTradesReceived
  | BlotterHistoricalTradesRequestFailed
  | BlotterFetchTradesAndOrdersStarted
  | BlotterFetchTradesAndOrdersEpic
  | BlotterOpenTileFromTrade;

export interface BlotterToggle {
  type: 'BLOTTER_TOGGLE';
  isOpen?: boolean;
}

export function blotterToggle(isOpen?: boolean): BlotterToggle {
  return {
    type: 'BLOTTER_TOGGLE',
    isOpen,
  };
}

export interface BlotterUpdateModeMetadata {
  type: 'BLOTTER_UPDATE_MODE_METADATA';
  modeMetadata: BlotterModeMetadata;
}

export function blotterUpdateModeMetadata(
  modeMetadata: BlotterModeMetadata,
): BlotterUpdateModeMetadata {
  return { type: 'BLOTTER_UPDATE_MODE_METADATA', modeMetadata };
}

export interface BlotterCantReopenEntry {
  type: 'BLOTTER_CANT_REOPEN_ENTRY';
  reason: CantReopenReason | null;
}

export function blotterCantReopenEntry(reason: CantReopenReason | null): BlotterCantReopenEntry {
  return { type: 'BLOTTER_CANT_REOPEN_ENTRY', reason };
}

export interface BlotterEntryReceived {
  type: 'BLOTTER_ENTRY_RECEIVED';
  entry: BlotterEntry;
}

export function blotterEntryReceived(entry: BlotterEntry): BlotterEntryReceived {
  return {
    type: 'BLOTTER_ENTRY_RECEIVED',
    entry,
  };
}

export interface BlotterOrderListReceived {
  type: 'BLOTTER_ORDER_LIST_RECEIVED';
  orders: readonly OrderBlotterEntry[];
}

export function blotterOrderListReceived(
  orders: readonly OrderBlotterEntry[],
): BlotterOrderListReceived {
  return {
    type: 'BLOTTER_ORDER_LIST_RECEIVED',
    orders,
  };
}

export interface BlotterIntradayTradesReceived {
  type: 'BLOTTER_INTRADAY_TRADES_RECEIVED';
  trades: readonly TradeBlotterEntry[];
}

export function blotterIntradayTradesReceived(
  trades: readonly TradeBlotterEntry[],
): BlotterIntradayTradesReceived {
  return {
    type: 'BLOTTER_INTRADAY_TRADES_RECEIVED',
    trades,
  };
}

export interface BlotterFetchTradesAndOrdersStarted {
  type: 'BLOTTER_FETCH_INTRADAY_TRADES_STARTED';
  ordersToKeep?: string[];
}

export function blotterFetchIntradayTradesStarted(
  ordersToKeep?: string[],
): BlotterFetchTradesAndOrdersStarted {
  return {
    type: 'BLOTTER_FETCH_INTRADAY_TRADES_STARTED',
    ordersToKeep,
  };
}

export interface BlotterIntradayTradesRequestFailed {
  type: 'BLOTTER_INTRADAY_TRADES_REQUEST_FAILED';
}

export function blotterIntradayTradesRequestFailed(): BlotterIntradayTradesRequestFailed {
  return {
    type: 'BLOTTER_INTRADAY_TRADES_REQUEST_FAILED',
  };
}

export interface BlotterOrderListReceivedFailed {
  type: 'BLOTTER_ORDER_LIST_REQUEST_FAILED';
}

export function blotterOrderListRequestFailed(): BlotterOrderListReceivedFailed {
  return {
    type: 'BLOTTER_ORDER_LIST_REQUEST_FAILED',
  };
}

export interface BlotterTabChanged {
  type: 'BLOTTER_TAB_CHANGED';
  tab: BlotterTab;
}

export function blotterTabChanged(tab: BlotterTab): BlotterTabChanged {
  return {
    type: 'BLOTTER_TAB_CHANGED',
    tab,
  };
}
export interface BlotterPanelHeightChanged {
  type: 'BLOTTER_PANEL_HEIGHT_CHANGED';
  height: number;
}

export function blotterPanelHeightChanged(height: number): BlotterPanelHeightChanged {
  return {
    type: 'BLOTTER_PANEL_HEIGHT_CHANGED',
    height,
  };
}

export interface BlotterLiveRegisterSuccess {
  type: 'BLOTTER_LIVE_REGISTER_SUCCESS';
}

export function blotterLiveRegisterSuccess(): BlotterLiveRegisterSuccess {
  return {
    type: 'BLOTTER_LIVE_REGISTER_SUCCESS',
  };
}

export interface BlotterFetchTradesAndOrdersEpic {
  type: 'BLOTTER_FETCH_TRADES_AND_ORDERS_EPIC';
}

export function blotterFetchTradesAndOrdersEpic(): BlotterFetchTradesAndOrdersEpic {
  return {
    type: 'BLOTTER_FETCH_TRADES_AND_ORDERS_EPIC',
  };
}

export interface BlotterLiveRegisterStart {
  type: 'BLOTTER_LIVE_REGISTER_START';
}

export function blotterLiveRegisterStart(): BlotterLiveRegisterStart {
  return {
    type: 'BLOTTER_LIVE_REGISTER_START',
  };
}

export interface BlotterLiveRegisterFailure {
  type: 'BLOTTER_LIVE_REGISTER_FAILURE';
  errorBlotterType?: string[] | null;
}

export function blotterLiveRegisterFailure(errorBlotterType?: string[] | null): BlotterLiveRegisterFailure {
  return {
    type: 'BLOTTER_LIVE_REGISTER_FAILURE',
    errorBlotterType
  };
}

export interface BlotterTabMetadataChanged {
  type: 'BLOTTER_TAB_METADATA_CHANGED';
  tab: BlotterTab;
  tabMetatada: Partial<BlotterTabMetadata>;
}

export function blotterTabMetadataChanged(
  tab: BlotterTab,
  tabMetatada: Partial<BlotterTabMetadata>,
): BlotterTabMetadataChanged {
  return {
    type: 'BLOTTER_TAB_METADATA_CHANGED',
    tab,
    tabMetatada,
  };
}

export interface BlotterTableCrashed {
  type: 'BLOTTER_TABLE_CRASHED';
}

export function blotterTableCrashed(): BlotterTableCrashed {
  return {
    type: 'BLOTTER_TABLE_CRASHED',
  };
}

export interface BlotterRetrieveHistoricalTrades {
  type: 'BLOTTER_RETRIEVE_HISTORICAL_TRADES';
}

export function blotterRetrieveHistoricalTrades(): BlotterRetrieveHistoricalTrades {
  return {
    type: 'BLOTTER_RETRIEVE_HISTORICAL_TRADES',
  };
}

export interface BlotterAllowRefetchTrades {
  type: 'BLOTTER_ALLOW_REFETCH_TRADES';
}

export function blotterAllowRefetchTrades(): BlotterAllowRefetchTrades {
  return {
    type: 'BLOTTER_ALLOW_REFETCH_TRADES',
  };
}

export interface BlotterHistoricalTradesReceived {
  type: 'BLOTTER_HISTORICAL_TRADES_RECEIVED';
  trades: readonly TradeBlotterEntry[];
}

export function blotterHistoricalTradesReceived(
  trades: readonly TradeBlotterEntry[],
): BlotterHistoricalTradesReceived {
  return {
    type: 'BLOTTER_HISTORICAL_TRADES_RECEIVED',
    trades,
  };
}

export interface BlotterHistoricalTradesRequestFailed {
  type: 'BLOTTER_HISTORICAL_TRADES_REQUEST_FAILED';
  error: BlotterHistoricalError;
  errorDetails?: string;
}

export function blotterHistoricalTradesRequestFailed(
  error: BlotterHistoricalError,
  errorDetails?: string,
): BlotterHistoricalTradesRequestFailed {
  return {
    type: 'BLOTTER_HISTORICAL_TRADES_REQUEST_FAILED',
    error,
    errorDetails,
  };
}

interface BlotterOpenTileFromTrade {
  type: 'BLOTTER_OPEN_TILE_FROM_TRADE';
}
export function blotterOpenTileFromTrade(): BlotterOpenTileFromTrade {
  return {
    type: 'BLOTTER_OPEN_TILE_FROM_TRADE',
  };
}
