import { setupSGWTConnect } from '@sgwt/connect-core';

const sgConnectConfig = window.sgmeConfiguration.sgConnect;
const overrideClientId =  sessionStorage.getItem('E2eClientIdPersona');
const overrideMailUser = sessionStorage.getItem('OverrideMailUser')

if (overrideClientId && window.sgmeConfiguration.env === "DEVE2E") {
    sgConnectConfig.client_id = overrideClientId;
}

export const sgwtConnect =  setupSGWTConnect(sgConnectConfig);

export const getFakeUser = (): string | undefined => {
  // @TODO maybe add check for production environment: window.sgmeConfiguration.env.toLowerCase().includes('production')
  return overrideMailUser ?? sgwtConnect.getIdTokenClaims()?.sub;
};